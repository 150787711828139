import { PageProps } from "gatsby"
import React from "react"
import { useTranslation } from "react-i18next"
import Layout from "../components/layout/Layout"
import { NotFound } from "../components/pages/not-found/NotFound"
import SEO from "../components/utils/SEO"
import LocalizationConstant from "../i18n/LocalizationConstant"

export default function NotFoundPage(props: PageProps) {
  const { t } = useTranslation()
  return (
    <Layout pageProps={props}>
      <SEO title={t(LocalizationConstant.NOT_FOUND_PAGE_TITLE)}/>
      <NotFound />
    </Layout>
  )
}
