import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import { useTranslation } from "react-i18next"
import LocalizationConstant from "../../../i18n/LocalizationConstant"
import { Title } from "../../shared/Title"

export function NotFound() {
  const { t } = useTranslation()
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <Title
        title={t(LocalizationConstant.NOT_FOUND_TITLE)}
        subtitles={[t(LocalizationConstant.NOT_FOUND_DESCRIPTION)]}
      />
      <StaticImage
        layout="constrained"
        placeholder="blurred"
        className="align-self-center w-25"
        src="../../../images/not-found.png"
        alt={t(LocalizationConstant.NOT_FOUND_PAGE_TITLE)}
      />
      <Link to="/" className="btn btn-outline-light my-5">
        {t(LocalizationConstant.NOT_FOUND_BUTTON_TEXT)}
      </Link>
    </div>
  )
}
